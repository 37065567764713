import React, { FC } from 'react';
import styled from 'styled-components';

interface ErrorMessageUpgradeProps {
  errorMessage?: string;
}

const ErrorMessageText = styled.p.attrs({ type: 'bodyS' })`
  color: #eb4c49;
  max-width: 18.438rem;
  margin: 0.7rem auto;
  text-align: center;
  font-size: 0.9375rem;
`;

const ErrorMessageUpgrade: FC<ErrorMessageUpgradeProps> = ({
  errorMessage,
  ...props
}) => {
  if (!errorMessage) return null;
  return <ErrorMessageText {...props}>{errorMessage}</ErrorMessageText>;
};

export default ErrorMessageUpgrade;
